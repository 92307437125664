<template>
  <Spinner />
</template>
<script>
import { jemMixin } from '../mixins/jemMixin'
import Spinner from '../components/common/spinner'

export default {
  name: 'MobileReports',
  components: {
    Spinner
  },
  mixins: [
    jemMixin
  ],
  async mounted () {
    var jemToken = await this.getJemToken()
    var redirectUrl = process.env.VUE_APP_JEM_REDIRECT_URL + jemToken
    window.location.href = redirectUrl
  }
}
</script>
