import jemAPI from '../communications/interceptors/apiInterceptor'
import { mapActions } from 'vuex'
export const jemMixin = {
  data () {
    return {
    }
  },
  methods: {
    ...mapActions([
      'seto2AccountNumbers',
      'setPermissionAction'
    ]),
    getJemToken: async function () {
      var rtn = ''
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        var payload = {}
        payload.UserName = this.$store.getters.username
        payload.CompanyName = this.$store.getters.currentCompany.name.replace('\'', '')
        payload.CompanyId = this.$store.getters.currentCompany.id
        payload.HomeUrl = process.env.VUE_APP_SERVER_LOC + 'home'
        var sites = this.$store.getters.sites
        var siteList = []
        for (let i = 0; i < sites.length; i++) {
          siteList.push(sites[i].id)
        }
        payload.SiteList = siteList
        await jemAPI.post(`jem/auth/token/`, JSON.stringify(payload), axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    },
    setJemO2AccountNumbers: async function () {
      var rtn = ''
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      try {
        var sites = this.$store.getters.sites
        var siteList = []
        for (let i = 0; i < sites.length; i++) {
          siteList.push(sites[i].id)
        }
        var payload = {}
        payload.siteList = siteList.join(',')
        await jemAPI.post(`jem/areaaccountnumbers/sitelist`, JSON.stringify(payload), axiosConfig).then((response) => {
          if (response.data.length === 0) {
            this.seto2AccountNumbers([])
          } else {
            this.seto2AccountNumbers(response.data)
          }
          this.setPermissionAction({ action: 'hasJemAccount', value: response.data.length > 0 })
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return rtn
    }
  }
}
